<template>
  <div id="image-with-validate">
    <b-form-file
        @change="onChange"
        :placeholder="placeholderDefault"
        accept="image/*"
    ></b-form-file>
    <span
        v-if="showButtonRemove"
        @click="removeElementImage()"
        class="text-body-1 mt-2 mb-2 red--text cursor-pointer">
        <v-icon small color="red">mdi-delete</v-icon> xóa image
    </span>
    <!-- <b-img class="mt-1" rounded :src="image_data"></b-img> -->
  </div>
</template>

<script>
export default {
  name: "B-ImageWithValidateSize",
  props: {
    // Pass the files info when it's done
    done: {
      type: Function,
      default: () => {},
    },
    showButtonRemove: {
      type: Boolean,
      default: true,
    },
    fileLabel: {
      type: String,
      default: 'Avatar',
    },
    filesize: {
      type: Number,
      default: 5242880, // default 5MB
    }
  },
  data() {
    return {
      placeholderDefault: 'Chọn tệp tin hoặc cancel để xóa',
      // originalImg: '',
    };
  },
  methods: {
    errorMess(msg = '') {
      this.$toasted.error(msg, {theme: "toasted-primary", position: "top-right", duration: 4000,});
    },
    async onChange(e) {
      let file = e.target.files[0];
      if (file === undefined) {
        await this.removeElementImage();
        return;
      }
      // check file size not over 5MB
      if (file.size > this.filesize) {
        this.errorMess(`Dung lượng ${this.fileLabel} quá lớn. Dung lượng tối đa là ${this.filesize / 1024 / 1024}MB`);
        await this.removeElementImage();
        return;
      }
      await this.handleFile(file);
    },
    async handleFile(file) {
      let vm = this;
      let reader = new FileReader();
      reader.onload = (e) => {
        vm.done({
          blob: e.target.result,
          base64: e.target.result,
          file: file,
        });
      };
      await reader.readAsDataURL(file);
    },
    async removeElementImage() {
      let empty = {
        blob: null,
        base64: null,
        file: null,
      };
      await this.setDefaultLabelImg();
      await this.done(empty);
    },
    setDefaultLabelImg() {
      let ele = document.getElementById("image-with-validate");
      if (ele) {
        ele.firstChild.children[1].firstChild.textContent = this.placeholderDefault;
      }
    },
  },
}
</script>

<style scoped>

</style>